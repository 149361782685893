// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/Nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/Proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

